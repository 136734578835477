import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useRecoilValue } from 'recoil';

import { authAtom } from 'state';
import { useUserActions } from 'actions';
import { useNavigate } from 'react-router-dom';

export { Login };

function Login({ history }) {
    const auth = useRecoilValue(authAtom);
    const navigate = useNavigate();
    const userActions = useUserActions(navigate);

    useEffect(() => {
        // redirect to home if already logged in
        
        if (auth) {
            history?.push('/');
            navigate('/');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('E-mail is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setError, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ email, password }) {        
        return userActions.login(email, password)
            .then()
            .catch(error => {
                setError('apiError', { message: error });
            });
    }

    return (

        <div className="container login">
            <div className="row login-container">
                <div className="contents login-bg">
                    <div className="row justify-content-center col-md-6">
                        <div className="col-md-6 login-form">
                            <div className="mb-4">
                                <h3>Sign In to <strong>Flow</strong></h3>
                                <p className="mb-4">Our happy moments to mark it Done</p>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group first">
                                    <label htmlFor="email">E-mail</label>
                                    <input id="email" autoFocus name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                </div>
                                <div className="form-group last mb-4">
                                    <label htmlFor="password">Password</label>
                                    <input id="password" name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                </div>

                                <button disabled={isSubmitting} className="btn btn-outline-dark btn-block">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Log In
                                </button>
                            
                                {errors.apiError &&
                                    <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>
                                }
                            </form>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

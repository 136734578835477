import { Route, Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { authAtom } from '../state';

export { PrivateRoute };

//function PrivateRoute({ element: Element, ...rest }) {
    //const auth = useRecoilValue(authAtom);
    
    //return (
    //    <Route {...rest} render={props => {
    //        if (!auth) {
    //            // not logged in so redirect to login page with the return url
    //            return <Navigate to={{ pathname: '/login', state: { from: props.location } }} />
    //        }

    //        // authorized so return component
    //        return <Component {...props} />
    //    }} />
    //);
//}

const PrivateRoute = () => {
    const auth = useRecoilValue(authAtom); // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return auth ? <Outlet /> : <Navigate to="/login" />;
}
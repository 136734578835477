import React, { Component, useEffect, useState } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useUserActions } from '../actions';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../state';

import './NavMenu.css';


export function NavMenu() {
    const [collapsed, toggle] = useState(true);
    const auth = useRecoilValue(authAtom);
    const userActions = useUserActions();

    function toggleNavbar() {
        toggle(!collapsed);
    }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">Livinfy : Flow</NavbarBrand>
                    <NavbarToggler onClick={toggleNavbar} className="mr-2" />                    
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse float-end" isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                                {auth ? 
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/board">Board</NavLink>
                                    </NavItem>
                                : null}

                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/preview">Preview</NavLink>*/}
                                {/*</NavItem>                            */}

                                {auth ?
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/users">Users</NavLink>
                                    </NavItem>
                                : null}
                                {auth ?
                                    <li className="nav-item">                                    
                                        <a onClick={userActions.logout} className="text-dark nav-link">Logout ({auth.firstName})</a>
                                    </li>
                                : null}
                            </ul>
                            </Collapse>
                        
                </Container>
            </Navbar>
        </header>
    );
}

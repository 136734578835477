import React, { Component } from 'react';

export class Configuration extends Component {
    static displayName = Configuration.name;

    
    render() {
        
        return (
            <div>
                <h1>Configuration information</h1>
                <p>API address: {process.env.REACT_APP_API_URL}</p>
            </div>
        );
    }
}


import { useEffect, useState } from 'react';
import { useFetchWrapper } from '../helpers';
import { Swimlane } from './Swimlane';

import './Board.css';
import './Preview.css';

export function Board() {
    const fetchWrapper = useFetchWrapper();

    const [columns, setColumns] = useState([]);
    const [swimlanes, setSwimlanes] = useState([]);
    const [columnStyle, setColumnStyle] = useState(null);
    const baseUrl = `${process.env.REACT_APP_API_URL}/boardcontent`;
    
    useEffect(() => {    
        loadData();                
    }, []);

    function loadData() {

        fetchWrapper.get(`${baseUrl}/columns`).then(result => {

            setColumns(result);

            let colLength = (81 / result.length);
            
            setColumnStyle(
                {
                    maxWidth: colLength + "%",
                    flex: "0 0 " + colLength + "%"
                });
        });

        fetchWrapper.get(`${baseUrl}/swimlanes`).then(result => {
            setSwimlanes(result);
        });        
    }
        
    return (             

        <div>

            <div className="board-header border-bottom">
                <div className="board-col-first"></div>
                {
                    columns.map(column =>
                        <div key={column.id} className="board-col" style={columnStyle}>
                            <div className="column-header">
                                <h6 className="column-title">{column.title}</h6>
                            </div>
                        </div>
                    )}
            </div>

            <div className="board-frame">
                <div className="board-content">
                    {swimlanes.map(swimlane =>                                                    
                        <Swimlane key={swimlane.id} swimlane={swimlane} columns={columns} columnStyle={columnStyle} />
                    )}
                </div>
            </div>

        </div>

    );
}

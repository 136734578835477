import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import React, { Component } from 'react';

//import { Board } from './components/Board';
import { Preview } from './components/Preview';

import { PrivateRoute } from './components';
import { NavMenu } from './components/NavMenu';
import { history } from './helpers';
import { Users } from './users';
import { Configuration } from './components/Configuration';
import { Login, LoginTest } from './login';
import { Board } from './components';


export default class App extends Component {
    static displayName = App.name;

  render () {
      return (
          <div className="app-container bg-light">
              <BrowserRouter history={history}>
                  <NavMenu />
                  <div className="container">
                      <Routes>
                          <Route path='/' element={<PrivateRoute />}>
                              <Route path='/' element={<Board />} />
                          </Route>
                          <Route path='/users' element={<PrivateRoute />}>
                              <Route path='/users' element={<Users />} />
                          </Route>
                          <Route path="/login" element={<Login history={history} />} />
                          <Route path="/logintest" element={<LoginTest />} />
                          <Route path='/board' element={<PrivateRoute />}>
                              <Route path="/board" element={<Board />} />
                          </Route>
                          <Route path="/preview" element={<Preview />} />
                          <Route path='/conf' element={<Configuration />} />

                          {/*<Route path='/configuration' element={<PrivateRoute />}>*/}
                          {/*</Route>*/}
                          {/*<Route path="*" to element={<Users />} />*/}
                      </Routes>
                  </div>
              </BrowserRouter>
          </div>
      );
  }
}
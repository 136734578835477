////import { useEffect } from 'react';
////import { useForm } from "react-hook-form";
////import { yupResolver } from '@hookform/resolvers/yup';
////import * as Yup from 'yup';
////import { useRecoilValue } from 'recoil';

////import { authAtom } from 'state';
////import { useUserActions } from 'actions';
////import { useNavigate } from 'react-router-dom';

import './login.css';

export { LoginTest };

function LoginTest() {
    
    return (

        <div className="container login">
            <div className="row login-container">
                <div className="contents login-bg">
                    <div className="row justify-content-center col-md-6">
                            <div className="col-md-6 login-form">
                                <div className="mb-4">
                                    <h3>Sign In to <strong>Flow</strong></h3>
                                    <p className="mb-4">Our happy moments to mark it Done</p>
                                </div>
                                <form action="#" method="post">
                                    <div className="form-group first">
                                        <label htmlFor="username">Username</label>
                                    <input type="text" className="form-control" id="username" autoFocus />

              </div>
                                        <div className="form-group last mb-4">
                                    <label htmlFor="password">Password</label>
                                            <input type="password" className="form-control" id="password" />
                
                                         </div>

                                            {/*<div className="d-flex mb-5 align-items-center">*/}
                                            {/*    <label className="control control--checkbox mb-0"><span className="caption">Remember me</span>*/}
                                            {/*        <input type="checkbox" checked="checked" />*/}
                                            {/*        <div className="control__indicator"></div>*/}
                                            {/*    </label>*/}
                                            {/*    <span className="ml-auto"><a href="#" className="forgot-pass">Forgot Password</a></span>*/}
                                            {/*</div>*/}

                                <button type="button" className="btn btn-outline-dark btn-block">Log In</button>

                                                {/*<span className="d-block text-left my-4 text-muted"> or sign in with</span>*/}

                                                {/*<div className="social-login">*/}
                                                {/*    <a href="#" className="facebook">*/}
                                                {/*        <span className="icon-facebook mr-3"></span>*/}
                                                {/*    </a>*/}
                                                {/*    <a href="#" className="twitter">*/}
                                                {/*        <span className="icon-twitter mr-3"></span>*/}
                                                {/*    </a>*/}
                                                {/*    <a href="#" className="google">*/}
                                                {/*        <span className="icon-google mr-3"></span>*/}
                                                {/*    </a>*/}
                                                {/*</div>*/}
            </form>
                                        </div>
                        </div>
          
                </div>

            </div>
        </div>       


    )
}

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { authAtom, usersAtom } from '../state';
import { useUserActions } from '../actions';

export { Users };

function Users() {
    const auth = useRecoilValue(authAtom);
    const users = useRecoilValue(usersAtom);
    const userActions = useUserActions();
        
    useEffect(() => {
        userActions.getAll();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h3>Users list:</h3>
            {users &&
                <ul>
                    {users.map(user =>
                        <li key={user.id}>{user.firstName} {user.lastName}</li>
                    )}
                </ul>
            }
            {!users && <div className="spinner-border spinner-border-sm"></div>}
        </div>
    );
}

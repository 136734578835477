import { useEffect, useState } from 'react';
import { useFetchWrapper } from '../helpers';


export function Swimlane({ swimlane, columns, columnStyle }) {
    const fetchWrapper = useFetchWrapper();
    const [tasks, setTasks] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const baseUrl = `${process.env.REACT_APP_API_URL}/boardcontent`;
        
    useEffect(() => {    
        loadData();                
    }, []);

    function loadData() {
        fetchWrapper.get(`${baseUrl}/tasks/${swimlane.id}`).then(result => {
            setTasks(result);
        });    }
       
    function renderTask(task) {

        return (
            <div className="card mb-3 bg-light" key={task.id}>
                <div className="card-body">
                    <div className="float-start card-check">
                        <label className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" />
                            <span className="custom-control-label"></span>
                        </label>
                    </div>
                    <p className="card-title">{task.title}</p>

                    <div className="float-start card-assigned border-top">
                        <img src="https://eu.ui-avatars.com/api/?name=name+surname" width="25" height="25" className="rounded-circle float-start" alt="Avatar" />
                        <p>Name Surname</p>                                                      </div>
                    {/*<a className="btn btn-outline-primary btn-sm" href="#">View</a>*/}
                </div>

            </div>
        );

    }

    return (             

        <div className="swimlane" key={swimlane.id}>

            <div className="board-col-first">
                <div className="swimlane-title">
                    <div className="swimlane-title-content" onClick={() => setExpanded(!expanded)}>
                        <h6>{swimlane.title}</h6>
                        <p>{swimlane.description}</p>
                    </div>
                </div>
                <div className={"swimlane-collapse bi bi-chevron-compact-" + (!expanded ? "down" : "right")} id={"swimlane-collapse" + swimlane.id}></div>
            </div>

            <div className={"swimlane-content collapse" + (!expanded ? " show" : "")} id={"swimlane-content" + swimlane.id}>
                {columns.map(column =>
                    <div className="board-col" key={column.id} style={columnStyle}>

                        <div className="board-col-header d-grid">
                            <a href="#" className="btn btn-outline-secondary btn-xs btn-block"> + Add new </a>
                        </div>

                        <div className="board-col-content">
                            {tasks
                                .filter(task => task.columnId == column.id)
                                .map(task =>
                                    renderTask(task)
                                )}
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
}
